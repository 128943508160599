<template>
  <div>
    <v-card elevation="1">
      <v-card-actions class="py-1">
        <v-spacer></v-spacer>
        <v-col lg="3" md="3" sm="12" class="pb-0">
          <!-- <v-text-field
            label="Search by Country"
            append-icon="search"
            class="pb-0 pt-2"
            v-model="searchCountry"
            @keyup="searchByCountry"
              outlined
              shaped
          ></v-text-field>-->
          <v-autocomplete
            label="Search by Country"
            :items="Country"
            item-text="name"
            item-value="code"
            clearable
            outlined
            dense
            v-model="isoCountryCode"
            @change="getRateList"
          ></v-autocomplete>
        </v-col>

        <v-col lg="3" md="3" sm="12" class="pb-0">
          <v-text-field
            label="Search by Prefix"
            append-icon="search"
            class="pb-0"
            v-model="searchText"
            @keyup="searchByText"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-card-actions>
      <v-data-table
        :headers="headers"
        :items="rateListData"
        :sort-by="['prefix', 'description', 'cost']"
        :server-items-length="totalRateList"
        :options.sync="options"
      >
        <template v-slot:[`item.indexNo`]="{ item }">
          {{
            (options.page - 1) * options.itemsPerPage +
            (rateList.indexOf(item) + 1)
          }}
        </template>
        <template v-slot:[`item.viewAction`]="{ item }">
          <v-icon @click="viewOrder(item)" color="info">visibility</v-icon>
        </template>
      </v-data-table>
      <loading :active="isLoading" :loader="loader" />
    </v-card>
  </div>
</template>

<script>
import rateAPI from "@/services/rateAPI.js";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      //fullPage: false,
      loader: "bars",
      search: "",
      searchText: "",
      searchType: "",
      searchCountry: "",
      searchCont: "",
      isoCountryCode: "",
      moment: moment,
      options: {},
      totalRateList: 0,
      headers: [
        {
          text: "#",
          value: "indexNo"
        },
        {
          text: "Prefix",
          value: "prefix",
          align: "center",
          sortable: true
        },
        {
          text: "Description",
          value: "description",
          align: "center",
          sortable: true
        },
        {
          text: "Rate",
          value: "cost",
          align: "center",
          sortable: true
        },
        {
          text: "View Order Detail",
          value: "viewAction",
          align: "center"
        }
      ],
      Country: [
        {
          name: "Afghanistan",
          code: "AF"
        },
        {
          name: "Åland Islands",
          code: "AX"
        },
        {
          name: "Albania",
          code: "AL"
        },
        {
          name: "Algeria",
          code: "DZ"
        },
        {
          name: "American Samoa",
          code: "AS"
        },
        {
          name: "Andorra",
          code: "AD"
        },
        {
          name: "Angola",
          code: "AO"
        },
        {
          name: "Anguilla",
          code: "AI"
        },
        {
          name: "Antarctica",
          code: "AQ"
        },
        {
          name: "Antigua and Barbuda",
          code: "AG"
        },
        {
          name: "Argentina",
          code: "AR"
        },
        {
          name: "Armenia",
          code: "AM"
        },
        {
          name: "Aruba",
          code: "AW"
        },
        {
          name: "Australia",
          code: "AU"
        },
        {
          name: "Austria",
          code: "AT"
        },
        {
          name: "Azerbaijan",
          code: "AZ"
        },
        {
          name: "Bahamas",
          code: "BS"
        },
        {
          name: "Bahrain",
          code: "BH"
        },
        {
          name: "Bangladesh",
          code: "BD"
        },
        {
          name: "Barbados",
          code: "BB"
        },
        {
          name: "Belarus",
          code: "BY"
        },
        {
          name: "Belgium",
          code: "BE"
        },
        {
          name: "Belize",
          code: "BZ"
        },
        {
          name: "Benin",
          code: "BJ"
        },
        {
          name: "Bermuda",
          code: "BM"
        },
        {
          name: "Bhutan",
          code: "BT"
        },
        {
          name: "Bolivia, Plurinational State of",
          code: "BO"
        },
        {
          name: "Bonaire, Sint Eustatius and Saba",
          code: "BQ"
        },
        {
          name: "Bosnia and Herzegovina",
          code: "BA"
        },
        {
          name: "Botswana",
          code: "BW"
        },
        {
          name: "Bouvet Island",
          code: "BV"
        },
        {
          name: "Brazil",
          code: "BR"
        },
        {
          name: "British Indian Ocean Territory",
          code: "IO"
        },
        {
          name: "Brunei Darussalam",
          code: "BN"
        },
        {
          name: "Bulgaria",
          code: "BG"
        },
        {
          name: "Burkina Faso",
          code: "BF"
        },
        {
          name: "Burundi",
          code: "BI"
        },
        {
          name: "Cambodia",
          code: "KH"
        },
        {
          name: "Cameroon",
          code: "CM"
        },
        {
          name: "Canada",
          code: "CA"
        },
        {
          name: "Cape Verde",
          code: "CV"
        },
        {
          name: "Cayman Islands",
          code: "KY"
        },
        {
          name: "Central African Republic",
          code: "CF"
        },
        {
          name: "Chad",
          code: "TD"
        },
        {
          name: "Chile",
          code: "CL"
        },
        {
          name: "China",
          code: "CN"
        },
        {
          name: "Christmas Island",
          code: "CX"
        },
        {
          name: "Cocos (Keeling) Islands",
          code: "CC"
        },
        {
          name: "Colombia",
          code: "CO"
        },
        {
          name: "Comoros",
          code: "KM"
        },
        {
          name: "Congo",
          code: "CG"
        },
        {
          name: "Congo, the Democratic Republic of the",
          code: "CD"
        },
        {
          name: "Cook Islands",
          code: "CK"
        },
        {
          name: "Costa Rica",
          code: "CR"
        },
        {
          name: "Côte d'Ivoire",
          code: "CI"
        },
        {
          name: "Croatia",
          code: "HR"
        },
        {
          name: "Cuba",
          code: "CU"
        },
        {
          name: "Curaçao",
          code: "CW"
        },
        {
          name: "Cyprus",
          code: "CY"
        },
        {
          name: "Czech Republic",
          code: "CZ"
        },
        {
          name: "Denmark",
          code: "DK"
        },
        {
          name: "Djibouti",
          code: "DJ"
        },
        {
          name: "Dominica",
          code: "DM"
        },
        {
          name: "Dominican Republic",
          code: "DO"
        },
        {
          name: "Ecuador",
          code: "EC"
        },
        {
          name: "Egypt",
          code: "EG"
        },
        {
          name: "El Salvador",
          code: "SV"
        },
        {
          name: "Equatorial Guinea",
          code: "GQ"
        },
        {
          name: "Eritrea",
          code: "ER"
        },
        {
          name: "Estonia",
          code: "EE"
        },
        {
          name: "Ethiopia",
          code: "ET"
        },
        {
          name: "Falkland Islands (Malvinas)",
          code: "FK"
        },
        {
          name: "Faroe Islands",
          code: "FO"
        },
        {
          name: "Fiji",
          code: "FJ"
        },
        {
          name: "Finland",
          code: "FI"
        },
        {
          name: "France",
          code: "FR"
        },
        {
          name: "French Guiana",
          code: "GF"
        },
        {
          name: "French Polynesia",
          code: "PF"
        },
        {
          name: "French Southern Territories",
          code: "TF"
        },
        {
          name: "Gabon",
          code: "GA"
        },
        {
          name: "Gambia",
          code: "GM"
        },
        {
          name: "Georgia",
          code: "GE"
        },
        {
          name: "Germany",
          code: "DE"
        },
        {
          name: "Ghana",
          code: "GH"
        },
        {
          name: "Gibraltar",
          code: "GI"
        },
        {
          name: "Greece",
          code: "GR"
        },
        {
          name: "Greenland",
          code: "GL"
        },
        {
          name: "Grenada",
          code: "GD"
        },
        {
          name: "Guadeloupe",
          code: "GP"
        },
        {
          name: "Guam",
          code: "GU"
        },
        {
          name: "Guatemala",
          code: "GT"
        },
        {
          name: "Guernsey",
          code: "GG"
        },
        {
          name: "Guinea",
          code: "GN"
        },
        {
          name: "Guinea-Bissau",
          code: "GW"
        },
        {
          name: "Guyana",
          code: "GY"
        },
        {
          name: "Haiti",
          code: "HT"
        },
        {
          name: "Heard Island and McDonald Islands",
          code: "HM"
        },
        {
          name: "Holy See (Vatican City State)",
          code: "VA"
        },
        {
          name: "Honduras",
          code: "HN"
        },
        {
          name: "Hong Kong",
          code: "HK"
        },
        {
          name: "Hungary",
          code: "HU"
        },
        {
          name: "Iceland",
          code: "IS"
        },
        {
          name: "India",
          code: "IN"
        },
        {
          name: "Indonesia",
          code: "ID"
        },
        {
          name: "Iran, Islamic Republic of",
          code: "IR"
        },
        {
          name: "Iraq",
          code: "IQ"
        },
        {
          name: "Ireland",
          code: "IE"
        },
        {
          name: "Isle of Man",
          code: "IM"
        },
        {
          name: "Israel",
          code: "IL"
        },
        {
          name: "Italy",
          code: "IT"
        },
        {
          name: "Jamaica",
          code: "JM"
        },
        {
          name: "Japan",
          code: "JP"
        },
        {
          name: "Jersey",
          code: "JE"
        },
        {
          name: "Jordan",
          code: "JO"
        },
        {
          name: "Kazakhstan",
          code: "KZ"
        },
        {
          name: "Kenya",
          code: "KE"
        },
        {
          name: "Kiribati",
          code: "KI"
        },
        {
          name: "Korea, Democratic People's Republic of",
          code: "KP"
        },
        {
          name: "Korea, Republic of",
          code: "KR"
        },
        {
          name: "Kuwait",
          code: "KW"
        },
        {
          name: "Kyrgyzstan",
          code: "KG"
        },
        {
          name: "Lao People's Democratic Republic",
          code: "LA"
        },
        {
          name: "Latvia",
          code: "LV"
        },
        {
          name: "Lebanon",
          code: "LB"
        },
        {
          name: "Lesotho",
          code: "LS"
        },
        {
          name: "Liberia",
          code: "LR"
        },
        {
          name: "Libya",
          code: "LY"
        },
        {
          name: "Liechtenstein",
          code: "LI"
        },
        {
          name: "Lithuania",
          code: "LT"
        },
        {
          name: "Luxembourg",
          code: "LU"
        },
        {
          name: "Macao",
          code: "MO"
        },
        {
          name: "Macedonia, the Former Yugoslav Republic of",
          code: "MK"
        },
        {
          name: "Madagascar",
          code: "MG"
        },
        {
          name: "Malawi",
          code: "MW"
        },
        {
          name: "Malaysia",
          code: "MY"
        },
        {
          name: "Maldives",
          code: "MV"
        },
        {
          name: "Mali",
          code: "ML"
        },
        {
          name: "Malta",
          code: "MT"
        },
        {
          name: "Marshall Islands",
          code: "MH"
        },
        {
          name: "Martinique",
          code: "MQ"
        },
        {
          name: "Mauritania",
          code: "MR"
        },
        {
          name: "Mauritius",
          code: "MU"
        },
        {
          name: "Mayotte",
          code: "YT"
        },
        {
          name: "Mexico",
          code: "MX"
        },
        {
          name: "Micronesia, Federated States of",
          code: "FM"
        },
        {
          name: "Moldova, Republic of",
          code: "MD"
        },
        {
          name: "Monaco",
          code: "MC"
        },
        {
          name: "Mongolia",
          code: "MN"
        },
        {
          name: "Montenegro",
          code: "ME"
        },
        {
          name: "Montserrat",
          code: "MS"
        },
        {
          name: "Morocco",
          code: "MA"
        },
        {
          name: "Mozambique",
          code: "MZ"
        },
        {
          name: "Myanmar",
          code: "MM"
        },
        {
          name: "Namibia",
          code: "NA"
        },
        {
          name: "Nauru",
          code: "NR"
        },
        {
          name: "Nepal",
          code: "NP"
        },
        {
          name: "Netherlands",
          code: "NL"
        },
        {
          name: "New Caledonia",
          code: "NC"
        },
        {
          name: "New Zealand",
          code: "NZ"
        },
        {
          name: "Nicaragua",
          code: "NI"
        },
        {
          name: "Niger",
          code: "NE"
        },
        {
          name: "Nigeria",
          code: "NG"
        },
        {
          name: "Niue",
          code: "NU"
        },
        {
          name: "Norfolk Island",
          code: "NF"
        },
        {
          name: "Northern Mariana Islands",
          code: "MP"
        },
        {
          name: "Norway",
          code: "NO"
        },
        {
          name: "Oman",
          code: "OM"
        },
        {
          name: "Pakistan",
          code: "PK"
        },
        {
          name: "Palau",
          code: "PW"
        },
        {
          name: "Palestine, State of",
          code: "PS"
        },
        {
          name: "Panama",
          code: "PA"
        },
        {
          name: "Papua New Guinea",
          code: "PG"
        },
        {
          name: "Paraguay",
          code: "PY"
        },
        {
          name: "Peru",
          code: "PE"
        },
        {
          name: "Philippines",
          code: "PH"
        },
        {
          name: "Pitcairn",
          code: "PN"
        },
        {
          name: "Poland",
          code: "PL"
        },
        {
          name: "Portugal",
          code: "PT"
        },
        {
          name: "Puerto Rico",
          code: "PR"
        },
        {
          name: "Qatar",
          code: "QA"
        },
        {
          name: "Réunion",
          code: "RE"
        },
        {
          name: "Romania",
          code: "RO"
        },
        {
          name: "Russian Federation",
          code: "RU"
        },
        {
          name: "Rwanda",
          code: "RW"
        },
        {
          name: "Saint Barthélemy",
          code: "BL"
        },
        {
          name: "Saint Helena, Ascension and Tristan da Cunha",
          code: "SH"
        },
        {
          name: "Saint Kitts and Nevis",
          code: "KN"
        },
        {
          name: "Saint Lucia",
          code: "LC"
        },
        {
          name: "Saint Martin (French part)",
          code: "MF"
        },
        {
          name: "Saint Pierre and Miquelon",
          code: "PM"
        },
        {
          name: "Saint Vincent and the Grenadines",
          code: "VC"
        },
        {
          name: "Samoa",
          code: "WS"
        },
        {
          name: "San Marino",
          code: "SM"
        },
        {
          name: "Sao Tome and Principe",
          code: "ST"
        },
        {
          name: "Saudi Arabia",
          code: "SA"
        },
        {
          name: "Senegal",
          code: "SN"
        },
        {
          name: "Serbia",
          code: "RS"
        },
        {
          name: "Seychelles",
          code: "SC"
        },
        {
          name: "Sierra Leone",
          code: "SL"
        },
        {
          name: "Singapore",
          code: "SG"
        },
        {
          name: "Sint Maarten (Dutch part)",
          code: "SX"
        },
        {
          name: "Slovakia",
          code: "SK"
        },
        {
          name: "Slovenia",
          code: "SI"
        },
        {
          name: "Solomon Islands",
          code: "SB"
        },
        {
          name: "Somalia",
          code: "SO"
        },
        {
          name: "South Africa",
          code: "ZA"
        },
        {
          name: "South Georgia and the South Sandwich Islands",
          code: "GS"
        },
        {
          name: "South Sudan",
          code: "SS"
        },
        {
          name: "Spain",
          code: "ES"
        },
        {
          name: "Sri Lanka",
          code: "LK"
        },
        {
          name: "Sudan",
          code: "SD"
        },
        {
          name: "Suriname",
          code: "SR"
        },
        {
          name: "Svalbard and Jan Mayen",
          code: "SJ"
        },
        {
          name: "Swaziland",
          code: "SZ"
        },
        {
          name: "Sweden",
          code: "SE"
        },
        {
          name: "Switzerland",
          code: "CH"
        },
        {
          name: "Syrian Arab Republic",
          code: "SY"
        },
        {
          name: "Taiwan, Province of China",
          code: "TW"
        },
        {
          name: "Tajikistan",
          code: "TJ"
        },
        {
          name: "Tanzania, United Republic of",
          code: "TZ"
        },
        {
          name: "Thailand",
          code: "TH"
        },
        {
          name: "Timor-Leste",
          code: "TL"
        },
        {
          name: "Togo",
          code: "TG"
        },
        {
          name: "Tokelau",
          code: "TK"
        },
        {
          name: "Tonga",
          code: "TO"
        },
        {
          name: "Trinidad and Tobago",
          code: "TT"
        },
        {
          name: "Tunisia",
          code: "TN"
        },
        {
          name: "Turkey",
          code: "TR"
        },
        {
          name: "Turkmenistan",
          code: "TM"
        },
        {
          name: "Turks and Caicos Islands",
          code: "TC"
        },
        {
          name: "Tuvalu",
          code: "TV"
        },
        {
          name: "Uganda",
          code: "UG"
        },
        {
          name: "Ukraine",
          code: "UA"
        },
        {
          name: "United Arab Emirates",
          code: "AE"
        },
        {
          name: "United Kingdom",
          code: "GB"
        },
        {
          name: "United States",
          code: "US"
        },
        {
          name: "United States Minor Outlying Islands",
          code: "UM"
        },
        {
          name: "Uruguay",
          code: "UY"
        },
        {
          name: "Uzbekistan",
          code: "UZ"
        },
        {
          name: "Vanuatu",
          code: "VU"
        },
        {
          name: "Venezuela, Bolivarian Republic of",
          code: "VE"
        },
        {
          name: "Viet Nam",
          code: "VN"
        },
        {
          name: "Virgin Islands, British",
          code: "VG"
        },
        {
          name: "Virgin Islands, U.S.",
          code: "VI"
        },
        {
          name: "Wallis and Futuna",
          code: "WF"
        },
        {
          name: "Western Sahara",
          code: "EH"
        },
        {
          name: "Yemen",
          code: "YE"
        },
        {
          name: "Zambia",
          code: "ZM"
        },
        {
          name: "Zimbabwe",
          code: "ZW"
        }
      ],
      rateList: []
    };
  },

  watch: {
    options: {
      handler() {
        this.getRateList();
      },
      deep: true
    }
  },
  computed: {
    rateListData() {
      return this.rateList.filter(item => {
        const prefix = item.prefix.toLowerCase();
        const description = item.description.toLowerCase();
        const cost = item.cost.toLowerCase();
        const searchTerm = this.search.toLowerCase();

        return (
          prefix.includes(searchTerm) ||
          description.includes(searchTerm) ||
          cost.includes(searchTerm)
        );
      });
    }
  },
  methods: {
    async getRateList() {
      this.isLoading = true;
      const { page, itemsPerPage } = this.options;
      var data = {};
      try {
        if (this.searchText) {
            data.searchText= this.searchText,
            data.searchType= this.searchType
        }
        if (this.isoCountryCode) {
           data.isoCountryCode= this.isoCountryCode
        }
        let response = await rateAPI.getRateListApi(page, itemsPerPage, data);
        this.isLoading = false;
        this.totalRateList = response.listSize;
        this.rateList = response.kazooRates;
      } catch (error) {
        this.isLoading = false;
      }
    },

    searchByText() {
      if (/^[a-zA-Z]+$/.test(this.searchText)) {
      } else {
        this.searchType = "prefix";
      }
      this.getRateList();
    },

    viewOrder(item) {
      this.isLoading = true;
      this.$router.push(`/ratelist/${item.id}`);
      this.isLoading = false;
    },

    capitalizeText(str) {
      str = str.replace("_", " ").toLowerCase();
      str = str.split(" ");
      let newStr = "";
      for (let text of str) {
        text = text.charAt(0).toUpperCase() + text.slice(1);
        newStr += ` ${text}`;
      }
      return newStr;
    }
  }
};
</script>

<style scoped>
.text-capitalize {
  text-transform: capitalize;
}
</style>