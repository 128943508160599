const http = require('./http');

const getRateListApi = (page, viewSize, payload) => {
    let searchData = '';
    let isoCountryCode = '';
    if (payload) {
        if (payload.searchText) {
            searchData = `&${payload.searchType}=${payload.searchText}`;
        }
        if (payload.isoCountryCode) {
            isoCountryCode = `&isoCountryCode=${payload.isoCountryCode}`
        }
    }
    return new Promise((resolve, reject) => {
        http.get(`/getKazooRates?viewIndex=${page}&pageSize=${viewSize}${searchData}${isoCountryCode}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getRateDetail = (rateId) => {
    return new Promise((resolve, reject) => {
        http.get(`/getRateDetailsViaKazoo?rateId=${rateId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

module.exports = {
    getRateListApi,
    getRateDetail,
};